// stylelint-disable

// # Crimson Text

@font-face {
    font-family: 'Brandon Grotesque Regular';
    src: url('./fonts/BrandonGrotesque-Regular.woff2') format('woff2'),
        url('./fonts/BrandonGrotesque-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

//New Fonts
$font-main: 'Brandon Grotesque Regular', sans-serif;
$font-alt: 'brandon-grotesque', sans-serif;